import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  constructor(private config: ConfigService, private api: ApiService, private fb: FormBuilder, private contactService: ConfigService, private sanitizer: DomSanitizer) { }
  newsLetterForm: any ;
  quoteForm: any ;
  sending: boolean = false;
  error: boolean = false;
  success: boolean = false;
  title: any = {};
  footer: any = {};
  socialLinks: any = {};
  menu: any = {};
  services: any = {};
  sendMess: string = 'Send Now';
  ngOnInit(): void {
    //this.footer = this.getFooter();
    this.title = this.api.title;
    this.getSocialData('social');
    this.getMenuData('menu_links');
    this.getServicesData('services');
    this.getFooterData('footer');
    this.newsLetterForm = this.fb.group({
      'email' : [null, [Validators.required, Validators.email]],
    });
    this.quoteForm = this.fb.group({
      'name' : [null, Validators.required],
      'phone' : [null, Validators.required],
      'email' : [null, [Validators.required, Validators.email]],
      'message' : [null, Validators.required],
    });
    this.sending = false;
    this.success = false;
    this.error = false;
  }
  get email(): any {
    return this.newsLetterForm.get('email');
  }
  get name(): any {
    return this.quoteForm.get('name');
  }
  get phone(): any {
    return this.quoteForm.get('phone');
  }
  get cemail(): any {
    return this.quoteForm.get('email');
  }
  get message(): any {
    return this.quoteForm.get('message');
  }
  getSocialData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.socialLinks = data;
      }
    );
    return this.socialLinks = false;
  }
  getMenuData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.menu = data;
      }
    );
    return this.menu = false;
  }
  getServicesData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.services = data;
      }
    );
    return this.services = false;
  }
  getFooterData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.footer = data[0];
      }
    );
    return this.footer = false;
  }
  subscribeMessage(formData: NgForm) {
    this.sending = true;
    this.api.postData(formData,'newsletter').subscribe(
      data => {
        if(data)
        {
          this.success = data;
          this.sending = false;
          this.newsLetterForm.reset();
        }
        else{
          this.error = true;
          this.sending = false;
        }
      }
    );
  }
  sendQuoteMessage(formData: NgForm) {
    this.sendMess = 'Sending...';
    this.api.postData(formData, 'quote').subscribe(
      data => {
        if(data)
        {
          this.api.sendQuotMessage(formData).subscribe(
            mail => {
              if(mail === true)
              {
                this.sendMess = '<i class="icofont-check"></i> Sent';
                this.quoteForm.reset();
                setTimeout(() => {
                  this.chat_close_icon(mail);
                  this.sendMess = 'Send Now';
                }, 2000);
              }
              else{
                this.sendMess = 'Send Now';
              }
            }
          );
        }
        else{
          this.sendMess = 'Send Now';
        }
      }
    );
  }
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getFooter() {
    return this.config.getConfig().footer;
  }
  chat_on($event: any){ 
    this.quoteForm.reset();
    $(".Layout").show();
    $(".chat_on").hide(300);
  }
  chat_close_icon($event: any){
    this.quoteForm.reset();
    $(".Layout").hide();
    $(".chat_on").show(300);
  }

}
