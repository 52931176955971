<!-- ======= Frequently Asked Questions Section ======= -->
<section id="faq" class="faq" *ngIf="faq.status">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
        <h2 [innerHtml]="faq.heading"></h2>
        <p [innerHtml]="faq.description"></p>
    </div>

    <div class="faq-list">
        <ul>
        <li *ngFor="let list of faqs; let i = index" data-aos="fade-up" data-aos="fade-up" [attr.data-aos-delay]="delay[i]">
            <i class="bx bx-help-circle icon-help"></i> <a data-toggle="collapse" class="collapse" href="#faq-list-{{i}}"> {{list.question}} <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-{{i}}" class="collapse" data-parent=".faq-list">
            <p [innerHtml]="list.answer"></p>
            </div>
        </li>

        </ul>
    </div>

    </div>
</section><!-- End Frequently Asked Questions Section -->