import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { ApiService } from '../api.service';
declare var $: any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  constructor(private config: ConfigService, private api: ApiService) { }
  imgUrl = this.api.imgUrl;
  portfolio: any = {};
  title: any = {};
  //filters: any = {};
  portfolioItems: any = [];
  items: any = [];
  categoryData: any = [];
  tabsData: any = [];
  filterActive: string = 'all';
  ngOnInit(): void {
    this.title = this.api.title;
    //this.portfolio = this.getPortfolio();
    this.getPageData('pages',5);
    this.getGalleryData('gallery');
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.portfolio = data;
      }
    );
    return this.portfolio = false;
  }

  getGalleryData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.portfolioItems = data;
        this.items = data;
        this.categoryData = data.map((value:any) => {
          return value.class.toLowerCase();
        });
        this.tabsData = ["all", ...new Set(this.categoryData)];
      }
    );
    return this.items = false;
  }
  
  filterCategory=(category:string) => {
    this.filterActive = category;
    if(category === "all"){
        this.items = this.portfolioItems;
        return this.items;
    }
    const filteredData =  this.portfolioItems.filter((value:any) => {
        return value.class === category;
    })
    this.items = filteredData;
  }

  getPortfolio() {
    return this.config.getConfig().portfolio;
  }

}
