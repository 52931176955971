import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  clients: any = {};
  companies: any = {};
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.getPageData('pages',2);
    this.getClientsData('companies');
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.clients = data;
      }
    );
    return this.clients = false;
  }
  getClientsData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.companies = data;
      }
    );
    return this.companies = false;
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

}
