import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '../loader.service';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  showSpinner = false;
  constructor(private spinnerService: LoaderService, private cdRef: ChangeDetectorRef, private ngxService: NgxUiLoaderService) {
  }
  ngOnInit() {
    this.init();
  }
  init() {
    this.spinnerService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = (status === 'start');
      if(status === 'end' || status === 'stop')
      {
        this.ngxService.stop();
        this.ngxService.stopLoader("loader-01");
      }
      else
      {
        this.ngxService.start();
        this.ngxService.startLoader("loader-01"); 
      }
      this.cdRef.detectChanges();
    });
  }

}