<!-- ======= Features Section ======= -->
<section id="features" class="features" *ngIf="feature.status">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
        <h2>{{feature.title}}</h2>
        <p [innerHtml]="feature.description"></p>
    </div>

    <div class="row">
        <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-left">
            <div *ngFor="let list of features; let i = index" class="icon-box mt-5" [ngClass] = " (i === 0) ? 'mt-lg-0' : ''"  data-aos="fade-up" [attr.data-aos-delay]="delay[i]">
                <i class="{{list.icon}}"></i>
                <h4>{{list.title}}</h4>
                <p [innerHtml]="list.description" ></p>
            </div>
        </div>
        <div class="image col-lg-6 order-1 order-lg-2 " data-aos="zoom-in" data-aos-delay="100">
            <img src="assets/img/features.svg" alt="" class="img-fluid">
        </div>
    </div>

    </div>
</section><!-- End Features Section -->