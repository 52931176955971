<!-- ======= Footer ======= -->
<footer id="footer" *ngIf="footer.status">

    <div class="footer-top">
      <div class="container">
        <div class="row">
  
          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>{{title}}</h3>
            <p *ngIf="footer">{{footer.address}}<br>
              <strong>Phone:</strong>&nbsp;<span [innerHTML]="footer.phone"></span><br>
              <strong>Email:</strong> {{footer.email}}<br>
              <strong>Office Hours:</strong> {{footer.timings}}<br>
            </p>
          </div>
  
          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul *ngIf="menu">
              <li *ngFor="let menu of menu">
                <i class="bx bx-chevron-right"></i> 
                <div *ngIf="menu.outlet; then thenBlock else elseBlock"></div>
                <ng-template #thenBlock><a href = "{{menu.link}}" target = "{{menu.outlet ? '_blank' : '_self'}}">{{menu.title}}</a></ng-template>
                <ng-template #elseBlock><a [routerLink] = "menu.link" target = "{{menu.outlet ? '_blank' : '_self'}}">{{menu.title}}</a></ng-template>
              </li>
            </ul>
          </div>
  
          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li *ngFor="let list of services"><i class="bx bx-chevron-right"></i> <a routerLink="/services">{{ list.title.split("&", 1) }}</a></li>
            </ul>
          </div>
  
          <div class="col-lg-4 col-md-6 footer-newsletter" *ngIf="footer.newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Subscribe Our Newsletter to Get More Updates.</p>
            <form [formGroup]="newsLetterForm"  (ngSubmit)="subscribeMessage(newsLetterForm.value)">
              <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" formControlName="email"  type="email" name="email"><input type="submit" [disabled]="newsLetterForm.invalid" value="Subscribe">
            </form>
            <div *ngIf="email.invalid  && email.touched" class="validate">The email field is required</div>
          </div>
          <div class="col-lg-4 col-md-6 footer-newsletter" *ngIf="!footer.newsletter">
            <iframe class="mb-4 mb-lg-0" [src]="transform(footer.map)" frameborder="0" style="border:0; width: 100%; height: 240px;" allowfullscreen></iframe>
          </div>
  
        </div>
      </div>
    </div>
  
    <div class="container">
  
      <div class="copyright-wrap d-md-flex py-4">
        <div class="mr-md-auto text-center text-md-left">
          <div class="copyright">
            &copy; Copyright <strong><span>{{title}}</span></strong>. All Rights Reserved
          </div>
        </div>
        <div class="social-links text-center text-md-right pt-3 pt-md-0">
          <a *ngFor="let social of socialLinks.records" href="{{social.link}}" target="{{social.target}}" class="{{social.class}}"><i class="{{social.icon}}"></i></a>
        </div>
      </div>
  
    </div>
  </footer><!-- End Footer -->

  <div id="Smallchat" class="quote">
    <div class="Layout Layout-open Layout-expand Layout-right" style="background-color: #3F51B5;color: rgb(255, 255, 255);opacity: 5;border-radius: 10px;">
      <div class="Messenger_messenger">
        <div class="Messenger_header" style="background-color: rgb(79 75 198); color: rgb(255, 255, 255);">
          <h4 class="Messenger_prompt">Get A Quote</h4> <span class="chat_close_icon" (click)="chat_close_icon($event)">x</span> </div>
        <div class="Messenger_content">
          <div class="Messages">
            <form [formGroup]="quoteForm" class="php-email-form" (ngSubmit)="sendQuoteMessage(quoteForm.value)">
              <div class="form-group">
                <input type="text" required  name="name" class="form-control" formControlName="name"  placeholder="Full Name" />
                <div *ngIf="name.invalid  && name.touched" class="validate">The name field is required</div>
              </div>
              <div class="form-group">
                <input type="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control" name="email" formControlName="email" placeholder="Email Address" />
                <div *ngIf="cemail.invalid  && cemail.touched" class="validate">The email address field is required</div>
              </div>
              <div class="form-group">
                <input type="number" required  class="form-control" name="phone" formControlName="phone"  placeholder="Phone Number" />
                <div *ngIf="phone.invalid  && phone.touched" class="validate">The phone number field is required</div>
              </div>
              <div class="form-group">
                <textarea class="form-control"  required name="message" formControlName="message"  rows="5" placeholder="Message"></textarea>
                <div *ngIf="message.invalid  && message.touched" class="validate">Please write something for us</div>
              </div>
              <div class="send-button"><button [disabled]="quoteForm.invalid" type="submit" [innerHtml]="sendMess">Send Now</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="chat_on" (click)="chat_on($event)"> <span class="chat_on_icon"><i class="bx bx-envelope" aria-hidden="true"></i></span> </div>
  </div>