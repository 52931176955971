import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';

import { Observable, of, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';

import { LoaderService } from './loader.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  title = environment.title;
  apiUrl = environment.apiUrl;
  emailUrl = environment.emailUrl;
  quoteUrl = environment.quoteUrl;
  imgUrl = environment.imgUrl;
  locationUrl = environment.locationUrl;
  constructor( private http: HttpClient, public loaderService: LoaderService) { }

  apiCall(database: String){
    return this.http.get<any>(`${this.apiUrl}/`+database);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  getSettings(database: string, id?: any): Observable<any[]> {
    let uid = id || null;
    let url: string;
    if (uid !== null) {
      url = `${this.apiUrl}/${database}/${id}`;
      return this.http.get<any>(url).pipe(
        tap((setting) => {
          setTimeout(() => {
            this.loaderService.requestEnded();
          }, 2000);
          return setting;
        }),
        catchError(this.handleError( ` get for ${database}`, []))
      );
    } 
    else {
      url = `${this.apiUrl}/${database}`;
      return this.http.get<any>(url).pipe(
        map((setting) => {
          setTimeout(() => {
            this.loaderService.requestEnded();
          }, 2000);
          return setting['records'];
        }), catchError(this.handleError( ` get for ${database}`, []))
        );
    }
  }
  postData(formData: NgForm, database: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/${database}`, formData, httpOptions).pipe(
      tap(
        post => console.log(post)
      ),
      catchError(this.handleError('Post:', []))
    );
  }
  sendMessage(formData: NgForm): Observable<any> {
    return this.http.post<any>(`${this.emailUrl}`, formData, httpOptions).pipe(
      tap(
        message => console.log(message)
      ),
      catchError(this.handleError('Sending Message', []))
    );
  }
  sendQuotMessage(formData: NgForm): Observable<any> {
    return this.http.post<any>(`${this.quoteUrl}`, formData, httpOptions).pipe(
      tap(
        message => console.log(message)
      ),
      catchError(this.handleError('Sending Message', []))
    );
  }
  postMail(position: any): Observable<any> {
    let currentLatitude = position.coords.latitude;
		let currentLongitude = position.coords.longitude;
    return this.http.post<any>(`${this.locationUrl}`, { lat: currentLatitude, lng: currentLongitude }, httpOptions).pipe(
      tap(
        location => {
          console.log(location);
        }
      ),
      catchError(this.handleError('Sending Geolocation', []))
    );
  }
}
