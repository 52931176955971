<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center" *ngIf="slider.status">
    <div class="container-fluid" data-aos="fade-up">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 [innerHtml]="slider.heading"></h1>
          <h2 [innerHtml]="slider.description"></h2>
          <div *ngIf="slider.button"><a href="javascript:void(0);" (click)="chat_on($event)" class="btn-get-started scrollto">{{slider.button}}</a></div>
        </div>
        <div class="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="150">
          <img src="assets/img/hero-img.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>
</section><!-- End Hero -->