import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faq: any = {};
  faqs: any ={};
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.getPageData('pages', 10);
    this.getFaqData('faq');
  };
  getPageData(database: string, id?: number){
    this.api.getSettings(database, id).subscribe(
      data => {
        this.faq = data;
      }
    );
    return this.faq = false;
  };
  getFaqData(database: string){
    this.api.getSettings(database).subscribe(
      data => {
        this.faqs = data;
      }
    );
    return this.faqs = false;
  }
  delay = [100,200,300,400,500,600,700,800,900,1000,1100,1200,1300,1400,1500,1600,1700,1800,1900,2000];
}
