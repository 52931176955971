<app-slider></app-slider>
<app-about></app-about>
<!-- ======= Counts Section ======= -->
<section id="counts" class="counts" *ngIf="counter.status">
    <div class="container">
        <div class="row counters">
            <div *ngFor="let list of counters"class="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up" *ngIf="list.status" [innerHtml]="list.count"></span>
            <p *ngIf="list.status" [innerHtml]="list.name"></p>
            </div>
        </div>
    </div>
</section><!-- End Counts Section -->
<app-services></app-services>
<app-features></app-features>
<app-testimonials></app-testimonials>
<app-portfolio></app-portfolio>
<app-pricing></app-pricing>
<app-faq></app-faq>
<app-contact></app-contact>