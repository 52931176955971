<!-- ======= About Section ======= -->
<section id="about" class="about" *ngIf="about.status">
    <div class="container">
      <div class="section-title" *ngIf="abouttab">
        <h2>{{about.title}}</h2>
      </div>

      <div class="row" *ngIf="about">
        <div class="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="150">
          <img src="assets/img/about.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h3>{{about.heading}}</h3>
          <p class="font-italic" [innerHtml]="about.description"></p>
          <ul *ngIf="aboutus && aboutus.status" >
            <li *ngFor="let list of arrayDescription(aboutus.description);" [innerHtml]="addIcon(list)"></li>
          </ul>
          <a *ngIf="about.button" href="javascript:void(0);" (click)="chat_on($event)" class="read-more">{{about.button}} <i class="icofont-long-arrow-right"></i></a>
        </div>
      </div>

    </div>
  </section><!-- End About Section -->