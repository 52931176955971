export const configuration = {
    navigation: {
        menu: [
            {link : '/home', name : 'Home', active: 'home'},
            {link : '/about', name : 'About Us', active: 'about'},
            {link : '/services', name : 'Services', active: 'services'},
            {link : '/portfolio', name : 'Portfolio', active: 'portfolio'},
            {link : '/team', name : 'Team', active: 'team'},
            {link : '/contact', name : 'Contact Us', active: 'contact'},
        ]
    },
    about: {
        heading : 'We Make Online Solution for ',
        description : 'We are a close team of creatives, designers & developers who work together to create beautiful, engaging digital experiences. We take pride in delivering only the best.',
        button : 'Get A Quote',
        features : [
            {icon : 'fa-file-o', title : 'Requirement'},
            {icon : 'fa-search-plus', title : 'Research'},
            {icon : 'fa-gamepad', title : 'Design'},
            {icon : 'fa-code', title : 'Development'},
            {icon : 'fa-lightbulb-o', title : 'Quality Assurance'},
            {icon : 'fa-phone', title : 'Support'}
        ]
    },
    portfolio: {
        title : 'Portfolio',
        heading : 'Our Work',
        description : 'Statistics suggest that when customers complain, business owners and managers ought to get excited about it. The complaining customer represents a huge opportunity for more business.',
        class : 'design',
        itemMenu : [
            {class : 'videography', name : 'Videography'},
            {class : 'photography', name : 'Photography'},
            {class : 'design', name : 'Design'}
        ],
        items : [
            {class : 'videography', title : 'Pochampally Bank', img : 'works_1.jpg', url : 'www.pochampallybank.com'},
            {class : 'photography', title : 'Padmavamshi Textile Market', img : 'works_2.jpg', url : 'www.pvtmarket.in'},
            {class : 'design', title : 'YLNS COOP Bank', img : 'ylns.png', url : 'www.ylnscoopbank.com'},
            {class : 'design', title : 'Tag Digital', img : 'tagdigital.png', url : 'www.tagdigital.in'},
            {class : 'design', title : 'Loankawala', img : 'loankawala.png', url : 'www.loankawala.com'},
            {class : 'design', title : 'Kinship Technologies', img : 'kinship.png', url : 'www.kinshiptechnologies.com'},
            {class : 'design', title : 'Groupsadda', img : 'groupsadda.png', url : 'www.groupsadda.com'},
            {class : 'design', title : 'Foodly Online', img : 'foodly.png', url : 'www.foodlyonline.com'},
            {class : 'design', title : 'Medicon Therapeutics', img : 'medicon.png', url : 'www.medicontherapeutics.in'},
            {class : 'design', title : 'Green Grove International School', img : 'greengrove.png', url : 'www.greengroveinternationalschools.com'},
            {class : 'design', title : 'Tecknowledge Pvt Ltd', img : 'works_5.jpg', url : 'www.tecknowledge.in'},
            {class : 'design', title : 'Krishnam Photography', img : 'krishnam.png', url : 'www.krishnamphotography.com'},
            {class : 'design', title : 'Build Impex', img : 'bimpex.png', url : 'www.buildimpex.com'},
            {class : 'design', title : 'Matha Digi Press', img : 'matha.png', url : 'www.matha.in'},
            {class : 'design', title : 'K C Pullaiah Foundation', img : 'kcp.png', url : 'www.kcpullaiahfoundation.org'},
            {class : 'design', title : 'Bioplast Product', img : 'works_1.jpg', url : 'www.bioplastproduct.com'}
        ]
    },
    footer: {
        logo: 'PS4WORKS',
        address: 'Jaihind Enclave, Madhapur, Hyderabad, TS -500081',
        phone: ' +91-9603113211 <br/> +91-9030894779',
        email: 'info@ps4works.in',
    },
};