<!-- ======= Contact Section ======= -->
<section id="contact" class="contact section-bg" *ngIf="contact.status">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{contact.title}}</h2>
        <p [innerHtml] = "contact.description"></p>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="info-box mb-4">
            <i class="bx bx-map"></i>
            <h3>Our Address</h3>
            <p [innerHtml]="footer.address"></p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4">
            <i class="bx bx-envelope"></i>
            <h3>Email Us</h3>
            <p [innerHtml]="footer.email"></p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4">
            <i class="bx bx-phone-call"></i>
            <h3>Call Us</h3> 
            <p [innerHtml]="footer.phone"></p>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="col-lg-6 ">
          <agm-map [latitude]="lat" [longitude]="lng">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>

        <div class="col-lg-6">
          <form [formGroup]="contactForm" class="php-email-form" (ngSubmit)="sendContactMessage(contactForm.value)">
            <div class="form-row">
              <div class="col-md-6 form-group">
                <input type="text" required  name="name" class="form-control" formControlName="name"  placeholder="Your Name" />
                <div *ngIf="name.invalid  && name.touched" class="validate">The name field is required</div>
              </div>
              <div class="col-md-6 form-group">
                <input type="email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control" name="email" formControlName="email" placeholder="Your Email" />
                <div *ngIf="email.invalid  && email.touched" class="validate">The email field is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" required  class="form-control" name="subject" formControlName="subject"  placeholder="Subject" />
              <div *ngIf="subject.invalid  && subject.touched" class="validate">The subject field is required</div>
            </div>
            <div class="form-group">
              <textarea class="form-control"  required name="message" formControlName="message"  rows="5" placeholder="Message"></textarea>
              <div *ngIf="message.invalid  && message.touched" class="validate">Please write something for us</div>
            </div>
            <div class="mb-3">
              <div class="loading" *ngIf="sending">Loading</div>
              <div class="error-message" *ngIf="error">Something went wrong. Please try again</div>
              <div class="sent-message" *ngIf="success">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button [disabled]="contactForm.invalid" type="submit">Send Message</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->