<!-- ======= Pricing Section ======= -->
<section id="pricing" class="pricing section-bg"  *ngIf="pricing.status">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
        <h2>{{pricing.title}}</h2>
        <p [innerHtml]="pricing.description"></p>
    </div>

    <div class="row">

        <div *ngFor="let plan of plans; let i = index" class="col-lg-3 col-md-6" [ngClass]="(i !== 0)?'mt-4 mt-md-0':''" data-aos="fade-up" [attr.data-aos-delay]="delay[i]">
        <div class="box" [ngClass]="plan.featured ? 'featured' : ''">
            <span *ngIf="plan.advanced" class="advanced">Advanced</span>
            <h3>{{plan.title}}</h3>
            <h4><sup>{{plan.currency}}</sup>{{plan.price}}<span> / month</span></h4>
            <ul>
            <li>{{plan.downloads}}</li>
            <li>{{plan.extensions}}</li>
            <li>{{plan.tutorials}}</li>
            <li>{{plan.support}}</li>
            <li>{{plan.updates}}</li>
            </ul>
            <div class="btn-wrap" *ngIf="plan.buttontext">
                <a [attr.href]="(plan.buttonlink === '' || plan.buttonlink === '#')?'javascript:void(0);':plan.buttonlink" (click)="(plan.buttonlink === '' || plan.buttonlink === '#')?chat_on($event):''" class="btn-buy">{{plan.buttontext}}</a>
            </div>
        </div>
        </div>

    </div>

    </div>
</section><!-- End Pricing Section -->