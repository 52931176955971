import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  abouttab = this.router.url == '/about' ? true : false;
  about: any = {};
  aboutus: any = {};
  //description: any = [];
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService, private router: Router) { }
  ngOnInit(): void {
    this.getPageData('pages',1);
    this.getAboutData('about');
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.about = data;
      }
    );
    return this.about = false;
  }
  getAboutData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
          this.aboutus = data[0];
      }
    );
    return this.aboutus = false;
  }
  arrayDescription(description: string){
    return description.split("\n");
  }
  chat_on($event: any){ 
    $(".Layout").show();
    $(".chat_on").hide(300);
  }
  chat_close_icon($event: any){
    $(".Layout").hide();
    $(".chat_on").show(300);
  }
  addIcon($text: string){
    return "<i class='icofont-check-circled'></i> " + $text;
  }

}
