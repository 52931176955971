
<!-- ======= Mobile Navigation ======= -->
<nav class="mobile-nav d-lg-none" style="display: none;">
  <ul *ngIf="menu">
    <li *ngFor="let menu of menu.records" (click)="getActivetab(menu.active)" [class.active]="currentUrl === menu.link"><a [routerLink] = "menu.link">{{menu.title}}</a></li>
  </ul>
</nav>
<!-- End Mobile Navigation -->
<!-- ======= Header ======= -->
<header id="header" [ngStyle]="{'background-color': currentUrl !== '/home' ? 'rgba(24, 6, 185, 0.8)' : ''}" class="fixed-top">
    <div class="container-fluid">
  
      <div class="row justify-content-center">
        <div class="col-xl-9 d-flex align-items-center">
          <h1 class="logo mr-auto"><a routerLink="/home">{{title}}</a></h1>
          <!-- Uncomment below if you prefer to use an image logo -->
          <!-- <a routerLink="/home" class="logo mr-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
          <nav class="nav-menu d-none d-lg-block">
            <ul *ngIf="menu">
              <li *ngFor="let menu of menu.records" (click)="getActivetab(menu.active)" [class.active]="currentUrl === menu.link">
                <div *ngIf="menu.outlet; then thenBlock else elseBlock"></div>
                <ng-template #thenBlock><a href = "{{menu.link}}" target = "{{menu.outlet ? '_blank' : '_self'}}">{{menu.title}}</a></ng-template>
                <ng-template #elseBlock><a [routerLink] = "menu.link" target = "{{menu.outlet ? '_blank' : '_self'}}">{{menu.title}}</a></ng-template>
              </li>
            </ul>
          </nav><!-- .nav-menu -->
        </div>
      </div>
  
    </div>
  </header><!-- End Header -->