import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { Location } from "@angular/common";
import { ConfigService } from '../config.service';
import { ApiService } from '../api.service';
import { LoaderService } from '../loader.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  constructor(private router: Router, private config:ConfigService, private api: ApiService, location: Location, public loaderService: LoaderService) { 
    router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
      if (location.path() != "") {
        this.currentUrl = location.path();
      } else {
        this.currentUrl = "/home";
      }
    });
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.requestStarted();
    }/*
    if (event instanceof NavigationEnd) {
      this.loaderService.requestEnded();
    }
    if (event instanceof NavigationCancel) {
      this.loaderService.requestEnded();
    }
    if (event instanceof NavigationError) {
      this.loaderService.requestEnded();
    } */
  }

  currentUrl = "/home";
  activetab = "home";
  imgUrl = this.api.imgUrl;
  navigation: any = {};
  title: any = {};
  menu: any = {};
  ngOnInit(): void {
    this.navigation = this.getNavigation();
    this.title = this.api.title;
    this.menu = false;
    this.api.apiCall('menu_links').subscribe((data)=>{
      this.menu = data;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const inputTag = document.getElementById('main') as HTMLInputElement;
        if (inputTag != null) {
         inputTag.remove();
        }
        const node = document.createElement('script');
        node.src = 'assets/js/main.js';
        node.type = 'text/javascript';
        node.async = false;
        node.id = 'main';
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    });
    if ($('.nav-menu').length) {
      $('.mobile-nav').show();
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', () => {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav ul > li > a', () => {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }
  }
  getNavigation(){
    return this.config.getConfig();
  }
  getActivetab(tabname: string){
    this.activetab = tabname;
  }
}
