<!-- ======= Testimonials Section ======= -->
<section id="testimonials" class="testimonials section-bg" *ngIf="clients.status">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
        <h2>{{clients.title}}</h2>
        <p [innerHtml]="clients.description"></p>
    </div>
    <owl-carousel-o [options]="customOptions" *ngIf="companies">
        <ng-template *ngFor="let company of companies" carouselSlide>
            <img src="{{imgUrl}}/{{company.logo}}" [alt]="company.name" [title]="company.name">
        </ng-template>   
    </owl-carousel-o>
    </div>
</section><!-- End Testimonials Section -->