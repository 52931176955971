import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(private api: ApiService, private sanitizer: DomSanitizer, private fb: FormBuilder, private contactService: ConfigService) { }
  contactForm: any ;
  imgUrl = this.api.imgUrl;
  sending: boolean = false;
  error: boolean = false;
  success: boolean = false;
  contact: any = {};
  footer: any = {};
  url: SafeResourceUrl | undefined;
  today: number = Date.now();
  zoom: number = 12;
  lat: any = 17.4484772;
  lng: any = 78.3741361;
  ngOnInit(): void {
    this.getPageData('pages',6);
    this.getFooterData('footer');
    this.contactForm = this.fb.group({
      'name' : [null, Validators.required],
      'subject' : [null, Validators.required],
      'email' : [null, [Validators.required, Validators.email]],
      'message' : [null, Validators.required],
    });
    this.sending = false;
    this.success = false;
    this.error = false;
  }
  get name(): any {
    return this.contactForm.get('name');
  }
  get subject(): any {
    return this.contactForm.get('subject');
  }
  get email(): any {
    return this.contactForm.get('email');
  }
  get message(): any {
    return this.contactForm.get('message');
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.contact = data;
      }
    );
    return this.contact = false;
  }
  getFooterData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.footer = data[0];
        if(data[0].map !== '')
        {
          let latLng = data[0].map.split(',');
          this.lat = parseFloat(latLng[0]);
          this.lng = parseFloat(latLng[1]);
        }
      }
    );
    return this.footer = false;
  }
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  sendContactMessage(formData: NgForm) {
    this.success = false;
    this.error = false;
    this.sending = true;
    this.api.postData(formData, 'contact_us').subscribe(
      data => {
        if(data)
        {
          this.api.sendMessage(formData).subscribe(
            mail => {
              if(mail === true)
              {
                this.success = mail;
                this.sending = false;
                this.contactForm.reset();
              }
              else{
                this.error = true;
                this.sending = false;
              }
            }
          );
        }
        else{
          this.error = true;
          this.sending = false;
        }
      }
    );
  }

  cancel() {
    this.cancelForm();
  }

  cancelForm() {
    //this.router.navigate([{outlets: { popup: null }}] );
  }

  

}
