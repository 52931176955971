import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {
  feature: any = {};
  features: any = {};
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.getPageData('pages',8);
    this.getFeaturesData('features');
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.feature = data;
      }
    );
    return this.feature = false;
  }
  getFeaturesData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{ 
        this.features = data;
      }
    );
    return this.features = false;
  }
  delay = [100,200,300,400,500,600,700,800,900,1000,1100,1200];

}
