import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../api.service';
declare var $: any;
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
  testimonial: any = {};
  testimonials: any = {};
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.getPageData('pages',4);
    this.getTestimonialsData('testimonials');
  };
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.testimonial = data;
      }
    );
    return this.testimonial = false;
  };
  getTestimonialsData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{ 
        this.testimonials = data;
      }
    );
    return this.testimonials = false;
  };
  ngAfterViewInit() {
    
  }
  

}
