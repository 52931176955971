<!-- ======= Testimonials Section ======= -->
<section id="testimonials" class="testimonials section-bg" *ngIf="testimonial.status">
    <div class="container" data-aos="fade-up">

    <div class="section-title">
        <h2>{{testimonial.title}}</h2>
        <p [innerHtml]="testimonial.description"></p>
    </div>

    <div class="owl-carousel testimonials-carousel">

        <div *ngFor = "let list of testimonials" class="testimonial-item">
        <p>
            <i class="bx bxs-quote-alt-left quote-icon-left"></i>
            {{list.comments}}
            <i class="bx bxs-quote-alt-right quote-icon-right"></i>
        </p>
        <img src="{{imgUrl}}/{{list.image}}" class="testimonial-img" alt="">
        <h3 [innerHtml]="list.name"></h3>
        <h4 [innerHtml]="list.role"></h4>
        </div>

    </div>

    </div>
</section><!-- End Testimonials Section -->