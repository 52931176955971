import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  slider: any = {};
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.getPageData('pages',9);
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.slider = data;
      }
    );
    return this.slider = false;
  }
  chat_on($event: any){ 
    $(".Layout").show();
    $(".chat_on").hide(300);
  }
  chat_close_icon($event: any){
    $(".Layout").hide();
    $(".chat_on").show(300);
  }

}
