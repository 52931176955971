import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { GeolocationService } from '@ng-web-apis/geolocation';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private api: ApiService, private readonly geolocation$: GeolocationService) { }
  imgUrl = this.api.imgUrl;
  counter: any = {};
  counters: any = {};
  sent: boolean = false;
  ngOnInit(): void {
    this.getPageData('pages',11);
    this.getcounterData('counter');
    (this.sent === false) ? this.getPosition() : '';
  }
  getPosition() { 
    this.geolocation$.subscribe(
      position => {
        this.sentMail(position);
      }
    );
  }
  sentMail(position: any){
    this.api.postMail(position).subscribe(
      data => {
        this.sent = true;
      }
    );
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data => {
        this.counter = data;
      }
    );
    return this.counter = false;
  }
  getcounterData(database: string){
    this.api.getSettings(database).subscribe(
      data => {
        this.counters = data;
      }
    );
    return this.counters = false;
  }

}
