<!-- ======= Services Section ======= -->
<section id="services" class="services section-bg" *ngIf="service.status">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{service.title}}</h2>
        <p [innerHtml]="service.description"></p>
      </div>

      <div class="row">
        <div *ngFor="let list of services; let i = index" class="col-lg-4 col-md-6 d-flex align-items-stretch" [ngClass] = " (i === 0 || i === 1 || i === 2) ? 'mt-4 mt-lg-0' : 'mt-4'" data-aos="zoom-in" [attr.data-aos-delay]="delay[i]">
          <div class="icon-box iconbox-{{color[i]}}">
            <div class="icon">
              <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                <path stroke="none" stroke-width="0" fill="#f5f5f5" [attr.d]="stroke[i]" ></path>
              </svg>
              <i class="{{list.icon}}"></i>
            </div>
            <h4><a href="">{{list.title}}</a></h4>
            <p [innerHtml]="list.description" class="text-justify" ></p>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Services Section -->