// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  title: 'PS4WORKS',
  apiUrl: 'https://ps4works.in/api.php/records',
  emailUrl: 'https://ps4works.in/contactAct.php',
  quoteUrl: 'https://ps4works.in/quoteAct.php',
  imgUrl: 'assets/uploads',
  //locationUrl: 'https://ps4works.in/locationAct.php',
  locationUrl: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
