import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  pricing: any = {};
  plans: any = {};
  imgUrl = this.api.imgUrl;
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.getPageData('pages',7);
    this.getPlaningData('plans');
  }
  getPageData(database: string, id?: number){
    this.api.getSettings(database,id).subscribe(
      data =>{
        this.pricing = data;
      }
    );
    return this.pricing = false;
  }
  getPlaningData(database: string){
    this.api.getSettings(database).subscribe(
      data =>{
        this.plans = data;
      }
    );
    return this.plans = false;
  }
  delay = [100,200,300,400,500,600,700,800,900,1000,1100,1200];
  chat_on($event: any){ 
    $(".Layout").show();
    $(".chat_on").hide(300);
  }
  chat_close_icon($event: any){
    $(".Layout").hide();
    $(".chat_on").show(300);
  }

}
