<!-- ======= Portfolio Section ======= -->
<section id="portfolio" class="portfolio" *ngIf="portfolio.status">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{portfolio.title}}</h2>
        <p [innerHtml]="portfolio.description"></p>
      </div>

      <div class="row" *ngIf="tabsData">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li *ngFor="let category of tabsData" [ngClass]="filterActive == category ?'mx-1 filter-active':'mx-1'" (click)="filterCategory(category)">{{category}}</li>
          </ul>
        </div>
      </div>

      <div class="row portfolio-container" *ngIf="items">

        <div *ngFor="let item of items" class="col-lg-4 col-md-6 portfolio-item">
          <div class="portfolio-wrap">
            <img src="{{imgUrl}}/{{item.img}}" class="img-fluid" alt="{{item.title}}">
            <div class="portfolio-info">
              <h4>{{item.title}}</h4>
              <p>{{item.url}}</p>
            </div>
            <div class="portfolio-links">
              <a href="{{imgUrl}}/{{item.img}}" data-gall="portfolioGallery" class="venobox" title="{{item.title}}"><i class="bx bx-plus"></i></a>
              <a href="http://{{item.url}}" target="_blank" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Portfolio Section -->